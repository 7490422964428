// const imagesdir = `assets/images`;
// __dirname
module.exports = {
  siteTitle: 'Infinity Today', // <title>
  manifestName: 'Resume',
  manifestShortName: 'Landing', // max 12 characters
  // manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/favicon.png',
  // manifestIcon: 'src/assets/img/2019.png',
  // pathPrefix: `/gatsby-starter-resume/`, // This path is subpath of your hosting https://domain/portfolio
  // pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio

  firstName: 'Infinity',
  lastName: 'Today',

  featuredPhotos: [
    {
      href: `2024staff.jpg`,
      alt: `staff Photo for 2024`,
      caption: `Holiday 2024 Staff Photo`,
    },
    {
      href: `banner2023.jpeg`,
      alt: `staff Photo for 2023`,
      caption: `Holiday 2023 Staff Photo`,
    },
    {
      href: `banner2022.jpg`,
      alt: `staff Photo for 2022`,
      caption: `Holiday 2022 Staff Photo`,
    },
    {
      href: `banner.jpg`,
      alt: `Illistration of the ARC Staff`,
      caption: `Holiday 2020 Staff Photo`,
    },
    {
      href: `2020_s.jpg`,
      alt: `December Staff Photo 2020`,
      caption: `Holiday 2020 Staff Photo`,
    },
    {
      href: `2019_s.jpg`,
      alt: `Ready to Help You and Your Case`,
      caption: 'Holiday 2019 Staff Photo',
    },
    {
      href: `2018_s.jpg`,
      alt: `Staff photo`,
      caption: 'Holiday 2018 Staff Photo',
    },
  ],

  // featured links
  featuredLinks: [
    {
      icon: 'fas fa-envelope', // this is the Font Awesome icon name to use
      name: 'E-Mail',
      url: 'mailto:errol@infinitytoday.com',
    },

    {
      icon: 'fas fa-phone', // this is the Font Awesome icon name to use
      name: '(855) ON-MY-OWN ',
      phone: '855-666-9696 ',
      url: 'tel:18556669696',
    },
    {
      icon: 'fa-facebook-f', // this is the Font Awesome icon name to use
      name: 'Like Us',
      url: 'https://facebook.com/tomorrowhasnolimits',
    },
  ],

  email: 'eroll@infinitytoday.com',
  slogan: 'Because Tomorrow has No Limits',
  phone: '18556669696',
  altPhone: '(855) ON MY OWN',
  primaryPhone: '(855) 666 9696',
  address: '350 W Passaic St, Rochelle Park, NJ 07662', // Should become a hyperlink
  address2: 'Fourth Floor', // Should become a hyperlink
  addressUrl: 'https://goo.gl/maps/aWSLybhQfrQAru16A',

  blurb: 'Infinity Today is your NJ Support Coordination specialist.',
  blurbsubtitle:
    'We focus 100% of our time on you and your Individualized Service Plan (ISP)!',
};
